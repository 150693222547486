import { addDays } from 'date-fns/esm';
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';

import { FILTRO_DATA } from '~/constants';

export const getFilterByData = (filtroData, field = 'dataEmissao') => {
  const filter = [];
  if (filtroData === FILTRO_DATA.HOJE) {
    const data = new Date();
    filter.push(
      `(date(${field}) ge ${format(data, `yyyy-MM-dd`)} and date(${field}) le ${format(
        data,
        `yyyy-MM-dd`
      )})`
    );
  } else if (filtroData === FILTRO_DATA.ONTEM) {
    const data = addDays(new Date(), -1);
    filter.push(
      `(date(${field}) ge ${format(data, `yyyy-MM-dd`)} and date(${field}) le ${format(
        data,
        `yyyy-MM-dd`
      )})`
    );
  } else if (filtroData === FILTRO_DATA.ESTA_SEMANA) {
    const startDate = startOfWeek(new Date());
    const endDate = endOfWeek(new Date());

    filter.push(
      `(date(${field}) ge ${format(startDate, `yyyy-MM-dd`)} and date(${field}) le ${format(
        endDate,
        `yyyy-MM-dd`
      )})`
    );
  } else if (filtroData === FILTRO_DATA.ESTE_MES) {
    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(new Date());

    filter.push(
      `(date(${field}) ge ${format(startDate, `yyyy-MM-dd`)} and date(${field}) le ${format(
        endDate,
        `yyyy-MM-dd`
      )})`
    );
  }

  return filter;
};

export const getFilterNodeByData = (filtroData, chave = 'documentoDataEmissao') => {
  let filter = null;
  if (filtroData === FILTRO_DATA.HOJE) {
    const data = new Date();
    filter = {
      chave,
      valor: [format(data, `yyyy-MM-dd`), format(data, `yyyy-MM-dd`)],
      operador: 'betweenDate'
    };
  } else if (filtroData === FILTRO_DATA.ONTEM) {
    const data = addDays(new Date(), -1);
    filter = {
      chave,
      valor: [format(data, `yyyy-MM-dd`), format(data, `yyyy-MM-dd`)],
      operador: 'betweenDate'
    };
  } else if (filtroData === FILTRO_DATA.ESTA_SEMANA) {
    const startDate = startOfWeek(new Date());
    const endDate = endOfWeek(new Date());

    filter = {
      chave,
      valor: [format(startDate, `yyyy-MM-dd`), format(endDate, `yyyy-MM-dd`)],
      operador: 'betweenDate'
    };
  } else if (filtroData === FILTRO_DATA.ESTE_MES) {
    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(new Date());

    filter = {
      chave,
      valor: [format(startDate, `yyyy-MM-dd`), format(endDate, `yyyy-MM-dd`)],
      operador: 'betweenDate'
    };
  }
  return filter;
};
