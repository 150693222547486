import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { SET_FOCUS_QUANTIDADE } from '~/store/modules/pdv/pdv-constants';

import InputPesquisaProduto from '../../components/InputPesquisaProduto';
import { formatCurrency } from '~/utils/format';

const ModalConsultaRapidaProduto = ({ allowAddToCart }) => {
  const dispatch = useDispatch();

  const { showModalConsultaRapidaProduto } = useSelector(state => state.pdv);
  const [produto, setProduto] = useState(null);

  const handleSubmit = () => {
    handleClose();
    dispatch(
      pdvActions.selecionarProduto(produto, () => {
        dispatch({ type: SET_FOCUS_QUANTIDADE, focus: true });
      })
    );
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConsultaRapidaProduto({ show: false }));
  };

  return (
    <Mui.Dialog
      open={showModalConsultaRapidaProduto?.show}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          if (handleClose) handleClose();
        }
      }}
      PaperComponent={Mui.Paper}
      maxWidth={'lg'}
    >
      <Mui.DialogTitle>
        <Mui.AppBar color="primary" position="static">
          <Mui.Toolbar>
            <Mui.Box>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Consulta rápida de produto
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box flexGrow={1} />
          </Mui.Toolbar>
        </Mui.AppBar>
        <Mui.Box p={2}>
          <InputPesquisaProduto
            setFocus={true}
            callback={data => {
              setProduto(data);
            }}
          />
        </Mui.Box>
      </Mui.DialogTitle>
      <Mui.DialogContent dividers style={{ width: 720 }}>
        <Mui.Box p={2}>
          <Mui.Grid container item spacing={2}>
            <Mui.Grid item md={3}>
              <Mui.TextField
                label="Código"
                variant="outlined"
                value={produto?.codigo ?? ''}
                fullWidth
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Mui.Grid>
            <Mui.Grid item md={4}>
              <Mui.TextField
                label="Cód. barra"
                variant="outlined"
                value={produto?.ean ?? '-'}
                fullWidth
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Mui.Grid>
            <Mui.Grid item md={2}>
              <Mui.TextField
                label="Unid."
                variant="outlined"
                value={produto?.unidadeComercial ?? ''}
                fullWidth
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Mui.Grid>
            <Mui.Grid item md={3}>
              <Mui.TextField
                label="Preço venda"
                variant="outlined"
                value={formatCurrency(produto?.estProdutoImposto?.[0]?.precoVenda ?? 0)}
                fullWidth
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Mui.Grid>
            <Mui.Grid item md={12}>
              <Mui.TextField
                label="Descrição"
                variant="outlined"
                value={produto?.descricao ?? ''}
                fullWidth
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button
          size="large"
          variant="contained"
          className="button-success"
          onClick={handleSubmit}
          style={{ marginLeft: 16 }}
        >
          <MuiIcons.AddCircle />
          <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
            Adicionar produto
          </Mui.Typography>
        </Mui.Button>

        <Mui.Button
          size="large"
          onClick={() => {
            if (handleClose) {
              handleClose();
            }
          }}
        >
          <MuiIcons.Close />
          <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
            Cancelar (ESC)
          </Mui.Typography>
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ModalConsultaRapidaProduto;
