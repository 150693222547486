import { createSelector } from 'reselect';
import * as momentTz from 'moment-timezone';

/// UFs por Timezone
const ufTz2 = ['TO', 'MG', 'ES', 'RJ', 'SP', 'PR', 'SC', 'RS', 'GO', 'DF'];
const ufTz3 = ['MS', 'MT', 'PA', 'AP', 'MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA'];
const ufTz4 = ['AM', 'RR', 'RO'];

const rootState = state => state?.auth;

export const selectorRotina = rotina =>
  createSelector(rootState, substate => {
    const permissao = substate?.permissoes?.find(
      x => x.pctUsuarioPerfilId === substate?.usuarioPerfil?.id && x.pctRotinaRotina === rotina
    );

    return permissao?.acesso ?? false;
  });

export const selectorParametroAsBoolean = parametro =>
  createSelector(rootState, substate => {
    const parametroEncontrado = substate?.parametros?.find(x => x?.parametro === parametro);

    return parametroEncontrado?.valor ? parametroEncontrado?.valor === 'S' : false;
  });

export const selectorParametroAsInteger = parametro =>
  createSelector(rootState, substate => {
    const parametroEncontrado = substate?.parametros?.find(x => x?.parametro === parametro);

    return parametroEncontrado?.valor ? parseInt(parametroEncontrado?.valor) : null;
  });

export const getDataHoraAtualComTimeZone = () =>
  createSelector(rootState, substate => {
    const uf = substate?.empresa?.uf;

    const getDataHoraAtual = timeZone => {
      let data = new Date();

      switch (timeZone) {
        case -2:
          data = momentTz.tz('America/Sao_Paulo').format();
          break;

        case -3:
          data = momentTz.tz('America/Bahia').format();
          break;

        case -4:
          data = momentTz.tz('America/Manaus').format();
          break;
      }

      return data?.substr(0, 19);
    };

    if (ufTz2.indexOf(uf) > -1) {
      return getDataHoraAtual(-2);
    }

    if (ufTz3.indexOf(uf) > -1) {
      return getDataHoraAtual(-3);
    }

    if (ufTz4.indexOf(uf) > -1) {
      return getDataHoraAtual(-4);
    }

    return getDataHoraAtual(-5); // Se nao estiver entre 2, 3, 4 ou 5 (somente para o AC)
  });
