import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HotKeys } from 'react-hotkeys';
import { Paper } from '@material-ui/core';
import { addDays } from 'date-fns';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import currency from 'currency.js';
import { v4 as uuid } from 'uuid';

import toast from '~/services/toast';
import { MEIO_PAGAMENTO, TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency } from '~/utils/format';
import { selectorFechamento } from '~/store/modules/pdv/pdv-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import { CurrencyInputCustom } from '~/components/CustomInput';
import ParcelaPagamentoTableRow from '../../components/ParcelaPagamentoTableRow';
import * as S from './styles';

const ModalMultiploPagamento = () => {
  const dispatch = useDispatch();

  const { venda, tipoDocumento, itemPagamento, selects, matrizCondicaoPagamento } = useSelector(state => state.pdv);
  const { pagamentoSelecionado, ...showModalMultiploPagamento } = useSelector(
    state => state.pdv?.showModalMultiploPagamento
  );
  const { valorSaldoPagar } = useSelector(selectorFechamento([]));
  const idsClientePagamento = venda?.intCliente?.clienteMeioPagamento?.map(pagamento => pagamento.finMeiopagamentoId);
  
  const clienteCondicaoPagamento = matrizCondicaoPagamento?.map(pagamento => {
    if(idsClientePagamento?.includes(pagamento.id) || pagamento.nfePagamento === 5) {
      return { ...pagamento, enabled: true };
    } else {
      return { ...pagamento }
    }
  });
  
  let condicoesPagamento = venda?.intCliente?.id ? clienteCondicaoPagamento : matrizCondicaoPagamento;
  if (idsClientePagamento?.length === 0 || !venda?.intCliente || !idsClientePagamento) {
    condicoesPagamento = matrizCondicaoPagamento?.map(pagamento => {
      return { ...pagamento, enabled: true };
    });
  }

  const montarParcelas = (parcelaQuantidade, parcelaValor, venda) => {
    let parcelaVencimento = new Date();

    if (itemPagamento?.finMeiopagamento?.entraQuitado || itemPagamento?.finMeiopagamento?.tef) {
      let parcela = {
        uuid: uuid(),
        parcelaNumero: venda.parcelas.length + 1,
        parcelaQuantidade,
        parcelaVencimento,
        parcelaValor,
        parcelaValorJuros: 0,
        finMeiopagamento: itemPagamento?.finMeiopagamento,
        finMeiopagamentoId: itemPagamento?.finMeiopagamento?.id
      };

      if (
        itemPagamento?.meioPagamento?.tef &&
        itemPagamento?.fatCartaobandeira &&
        tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
      ) {
        parcela.fatCartaobandeira = itemPagamento?.fatCartaobandeira ?? null;
        parcela.fatCartaoBandeiraId = itemPagamento?.fatCartaobandeira?.id ?? null;
      }

      return [parcela];
    } else {
      const valoresParcelas = currency(parcelaValor).distribute(parcelaQuantidade) ?? [];

      let parcelaNumero = 0;
      const novasParcelas = [];
      for (let i = 0; i < parcelaQuantidade; i++) {
        // parcelaNumero++;
        if (parcelaQuantidade !== 1) {
          parcelaVencimento = addDays(parcelaVencimento, 30);
        }

        let parcela = {
          uuid: uuid(),
          parcelaNumero: (venda?.parcelas?.length + 1 + novasParcelas.length) ?? 0,
          parcelaQuantidade,
          parcelaVencimento,
          parcelaValor: Number(valoresParcelas[i]),
          parcelaValorJuros: 0,
          finMeiopagamento: itemPagamento?.finMeiopagamento,
          finMeiopagamentoId: itemPagamento?.finMeiopagamento?.id
        };

        novasParcelas.push(parcela);
      }
      return [...novasParcelas];
    }
  };

  const changeNumeroAutorizacao = numero => {
    dispatch(
      pdvActions.atualizarItemPagamento({
        ...itemPagamento,
        documentoNumero: numero
      })
    );
  };

  const changeBandeiraCartao = bandeira => {
    dispatch(
      pdvActions.atualizarItemPagamento({
        ...itemPagamento,
        fatCartaobandeira: bandeira
      })
    );
  };

  const changeValorRecebido = valorRecebido => {
    let valorTroco = 0;
    if (valorRecebido > valorSaldoPagar) {
      valorTroco = valorRecebido - valorSaldoPagar;
    }

    const valorParcelas = valorRecebido - valorTroco;
    const novasParcelas = montarParcelas(itemPagamento?.parcelaQuantidade, valorParcelas, venda);
    dispatch(pdvActions.atualizarItemPagamento({
      ...itemPagamento,
      valorRecebido,
      valorTroco,
      parcelas: [...novasParcelas]
    }));
  };

  const changeQuantidadeParcela = value => {
    const parcelaQuantidade = Number(value);
    if (parcelaQuantidade <= 0) {
      return;
    }

    const valorParcelas = itemPagamento?.valorRecebido - (itemPagamento?.valorTroco ?? 0);
    const novasParcelas = montarParcelas(parcelaQuantidade, valorParcelas, venda);

    dispatch(pdvActions.atualizarItemPagamento({
      ...itemPagamento,
      parcelaQuantidade,
      parcelaValor: valorParcelas,
      valorRecebido: valorParcelas,
      parcelas: [...novasParcelas]
    }));
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalMultiploPagamento({ show: false }));
  };

  const handleSubmit = () => {

    if(itemPagamento.parcelas.length === 0 ){
      const novasParcelas = montarParcelas(itemPagamento?.parcelaQuantidade, itemPagamento?.parcelaValor, venda);
      itemPagamento.parcelas = novasParcelas;
    }


    if (
      itemPagamento?.finMeiopagamento?.nfePagamento !== MEIO_PAGAMENTO.DINHEIRO &&
      itemPagamento?.valorRecebido > valorSaldoPagar
    ) {
      toast.warning('Valor informado maior que o saldo a pagar!');
      return;
    }

    if (
      (itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
        itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO) &&
      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
    ) {
      if (
        itemPagamento?.finMeiopagamento &&
        itemPagamento?.finMeiopagamento?.tef &&
        itemPagamento?.finMeiopagamento?.tefModalidade === null &&
        !itemPagamento?.documentoNumero
      ) {
        toast.warning('Informar o número do documento (POS)');
        return;
      }

      if (
        itemPagamento?.finMeiopagamento &&
        itemPagamento?.finMeiopagamento?.tef &&
        !itemPagamento?.fatCartaobandeira
      ) {
        toast.warning('Informar bandeira para pagamento com cartão crédito/débito');
        return;
      }
    }

    if (
      itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CHEQUE &&
      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA
    ) {
      itemPagamento?.parcelas?.forEach(parcela => {
        if (!parcela?.cheque) {
          toast.warning(`Informe o cheque da parcela (${parcela?.parcelaNumero})!`);
          return;
        }
      });

      if (itemPagamento?.parcelas?.every(x => !x.cheque)) {
        return;
      }
    }

    const totalParcelas = itemPagamento.parcelas.reduce((total, item) => total + item.parcelaValor, 0);

    if(totalParcelas === itemPagamento.parcelaValor || !itemPagamento.parcelaValor) {
      dispatch(
        pdvActions.setShowModalConsultaPagamento({
          show: true,
          fecharVenda: false,
          pagamentoSelecionado: 
            {
              meioPagamento: itemPagamento.finMeiopagamento,
              condicoes: itemPagamento.finMeiopagamento?.condicoes,
              condicaoPagamento:
                itemPagamento.finMeiopagamento?.condicoes?.length === 1
                  ? itemPagamento.finMeiopagamento?.condicoes[0]
                  : null
            }
        })
      );
    }

    itemPagamento.naoFechaVenda = true;

    dispatch(pdvActions.adicionarParcelas(itemPagamento));
    dispatch(pdvActions.iniciaPagamento());
  };

  useEffect(() => {
    if (itemPagamento?.finMeiopagamento) {
      dispatch(
        pdvActions.atualizarItemPagamento({
          ...itemPagamento,
          parcelas: [...montarParcelas(1, valorSaldoPagar, venda)]
        })
      );
    }
  }, [itemPagamento?.finMeiopagamento]);

  useEffect(() => {
    if (pagamentoSelecionado?.meioPagamento) {
      dispatch(
        pdvActions.atualizarItemPagamento({
          parcelaNumero: venda.parcelas.length + 1,
          parcelaQuantidade: 1,
          parcelaValor: valorSaldoPagar,
          documentoNumero: '',
          fatCartaobandeira: null,
          finMeiopagamento: pagamentoSelecionado?.meioPagamento,
          valorRecebido: valorSaldoPagar,
          parcelas: []
        })
      );
    }
  }, [pagamentoSelecionado?.meioPagamento]);

  const keyMap = {
    QUANTIDADE_PARCELA: {
      name: 'Alterar a quantidade parcela (*)',
      sequences: ['*']
    }
  };

  const handlers = {
    QUANTIDADE_PARCELA: () => {
    }
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers} contextMenu tabIndex="-1">
      <Mui.Dialog
        open={showModalMultiploPagamento?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  PAGAMENTO
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />
              <Mui.IconButton
                color="inherit"
                onClick={handleClose}
                style={{ marginLeft: 12 }}
                aria-label="close"
              >
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
          <S.Header>
            <Mui.Box display="flex" flexDirection="row">
              {itemPagamento?.finMeiopagamento && (
                <Mui.Fab
                  size="small"
                  onClick={() => {
                    dispatch(
                      pdvActions.atualizarItemPagamento({
                        finMeiopagamento: null
                      })
                    );
                  }}
                  tabIndex="-1"
                >
                  <MuiIcons.ArrowBack />
                </Mui.Fab>
              )}
              <Mui.Box ml={1}>
                <Mui.Typography variant="caption">Meio de pagamento</Mui.Typography>
                {itemPagamento?.finMeiopagamento ? (
                  <Mui.Typography variant="h5">
                    {itemPagamento?.finMeiopagamento?.descricao}
                  </Mui.Typography>
                ) : (
                  <Mui.Typography variant="h5">SELECIONAR PAGAMENTO</Mui.Typography>
                )}
              </Mui.Box>
            </Mui.Box>

            <Mui.Box textAlign="right">
              <Mui.Typography variant="caption" align="right">
                Saldo a pagar
              </Mui.Typography>
              <Mui.Typography
                variant="h3"
                align="right"
                style={{ textTransform: 'uppercase', fontWeight: 600 }}
              >
                {formatCurrency(valorSaldoPagar)}
              </Mui.Typography>
            </Mui.Box>
          </S.Header>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ width: 600 }}>
          {!itemPagamento?.finMeiopagamento ? (
            <S.List>
              {condicoesPagamento?.map((meio, index) => (
                <S.ListItem key={`index-${index}`}>
                  <S.ButtonPagamento
                    // autoFocus
                    fullWidth
                    focusRipple={false}
                    variant="outlined"
                    disabled={meio?.enabled ? false : true}
                    onClick={() => {
                      dispatch(
                        pdvActions.atualizarItemPagamento({
                          ...itemPagamento,
                          finMeiopagamento: { ...meio },
                          parcelaQuantidade: 1,
                          valorRecebido: valorSaldoPagar
                        })
                      );
                    }}
                  >
                    <Mui.Box
                      display="flex"
                      flex={1}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={2}
                      py={2}
                    >
                      <Mui.Typography align="left">{meio?.descricao}</Mui.Typography>
                      <MuiIcons.NavigateNext />
                    </Mui.Box>
                  </S.ButtonPagamento>
                </S.ListItem>
              ))}
            </S.List>
          ) : (
            <>
              <Mui.Grid container item>
                <Mui.Grid md={8}>
                  <Mui.Box p={2}>
                    <Mui.Typography variant="caption">Valor pago (R$)</Mui.Typography>
                    <Mui.TextField
                      fullWidth
                      autoFocus
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <Mui.InputAdornment position="start">R$</Mui.InputAdornment>
                        ),
                        inputComponent: CurrencyInputCustom,
                        style: {
                          height: 54,
                          fontSize: 32
                        }
                      }}
                      value={itemPagamento?.valorRecebido}
                      onChange={e => {
                        const valor = Number(e.floatvalue);
                        changeValorRecebido(valor);
                      }}
                    />
                  </Mui.Box>
                </Mui.Grid>
                <Mui.Grid md={4}>
                  <Mui.Box p={2}>
                    <Mui.Typography variant="caption">Quantidade parcela (*)</Mui.Typography>
                    <Mui.TextField
                      fullWidth
                      variant="outlined"
                      type="number"
                      min={999}
                      InputProps={{
                        style: {
                          height: 54,
                          fontSize: 32
                        }
                      }}
                      disabled={
                        itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.DINHEIRO ||
                        itemPagamento?.finMeiopagamento?.nfePagamento ===
                          MEIO_PAGAMENTO.CARTAO_DEBITO
                      }
                      value={itemPagamento?.parcelaQuantidade}
                      onChange={e => changeQuantidadeParcela(e.target.value)}
                    />
                  </Mui.Box>
                </Mui.Grid>
              </Mui.Grid>

              {itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.DINHEIRO && (
                <Mui.Box p={2} display="flex" flexDirection="column" alignItems="center">
                  <Mui.Typography variant="caption">Troco (R$)</Mui.Typography>

                  <Mui.Typography variant="h1">
                    {formatCurrency(itemPagamento?.valorTroco)}
                  </Mui.Typography>
                </Mui.Box>
              )}

              {(itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
                itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO) &&
                tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && itemPagamento?.finMeiopagamento?.tef === true && (
                  <>
                    {itemPagamento?.finMeiopagamento?.tefModalidade === null && (
                      <Mui.Box p={2}>
                        <Mui.Typography variant="caption">Número autorização</Mui.Typography>
                        <Mui.TextField
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            style: {
                              height: 54,
                              fontSize: 32
                            }
                          }}
                          value={itemPagamento?.documentoNumero}
                          onChange={e => {
                            changeNumeroAutorizacao(e.target.value);
                          }}
                        />
                      </Mui.Box>
                    )}

                    <Mui.Box p={2}>
                      <Mui.Typography align="left" variant="caption">
                        Bandeira do cartão
                      </Mui.Typography>
                      <Mui.Select
                        variant="outlined"
                        value={itemPagamento?.fatCartaobandeira ?? null}
                        onChange={e => changeBandeiraCartao(e.target.value)}
                        fullWidth
                      >
                        {selects?.bandeiras
                          ?.filter(x =>
                            x.operacao ==
                            (itemPagamento?.finMeiopagamento?.nfePagamento ===
                              MEIO_PAGAMENTO.CARTAO_CREDITO)
                              ? 0
                              : 1
                          )
                          ?.map((x, index) => (
                            <Mui.MenuItem key={index} value={x}>
                              {x.descricao}
                            </Mui.MenuItem>
                          ))}
                      </Mui.Select>
                    </Mui.Box>
                  </>
                )}
              {itemPagamento?.parcelas?.length > 0 &&
                !(
                  itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.DINHEIRO ||
                  itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_CREDITO ||
                  itemPagamento?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CARTAO_DEBITO
                ) && (
                  <Mui.Box p={2}>
                    <Mui.TableContainer component={Paper}>
                      <Mui.Table aria-label="spanning table">
                        <Mui.TableHead>
                          <Mui.TableRow>
                            <Mui.TableCell align="left">Parcela</Mui.TableCell>
                            <Mui.TableCell align="center">Data</Mui.TableCell>
                            <Mui.TableCell align="right">Valor</Mui.TableCell>
                            <Mui.TableCell align="right">#</Mui.TableCell>
                          </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                          {itemPagamento?.parcelas?.map((parcela, parcelaIndex) => (
                            <ParcelaPagamentoTableRow
                              key={parcelaIndex}
                              parcela={parcela}
                            />
                          ))}
                        </Mui.TableBody>
                      </Mui.Table>
                    </Mui.TableContainer>
                    <Mui.Paper></Mui.Paper>
                  </Mui.Box>
                )}
            </>
          )}
          {/* {JSON.stringify(itemPagamento)} */}
        </Mui.DialogContent>
        <Mui.DialogActions>
          {itemPagamento?.finMeiopagamento && (
            <Mui.Button
              startIcon={<MuiIcons.CheckCircle />}
              size="large"
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              <span style={{ marginLeft: 4 }}>CONFIRMAR PAGAMENTO</span>
            </Mui.Button>
          )}
          <Mui.Button startIcon={<MuiIcons.Close />} size="large" onClick={handleClose}>
            CANCELAR (ESC)
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </HotKeys>
  );
};

export default ModalMultiploPagamento;
