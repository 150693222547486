import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, TextField } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { ButtonBase } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Badge from '@material-ui/core/Badge';
import { WifiOff as IconWifiOfff, Wifi as IconWifi } from '@material-ui/icons';
import {
  AccountCircle as IconAccountCircle,
  KeyboardArrowDown as IconKeyboardArrowDown,
  ExitToApp as IconExitToApp,
  Help as IconHelp,
  SwapHoriz
} from '@material-ui/icons';
import * as MuiIcons from '@material-ui/icons';
import Swal from 'sweetalert2';

import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import * as authActions from '~/store/modules/auth/auth-actions';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as appActions from '~/store/modules/app/app-actions';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';
import { APPLICATION, APP_VERSION, TIPO_DOCUMENTO } from '~/constants';
import print from '~/services/print';
import signalr from '~/services/signalr';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 34,
    width: 34,
    marginRight: theme.spacing(0.5)
  },
  popover: {
    width: 340
  }
}));

const Account = ({ showPreferences, showHelp }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const ref = useRef(null);
  const location = useLocation();

  const { isOffline } = useSelector(state => state.app);
  const isOnline = useSelector(getApplicationIsOnline());

  const { tipoDocumento } = useSelector(state => state.pdv);
  const { usuario: usuarioLogado, usuarioPerfil, empresa } = useSelector(state => state.auth);
  const parametroTef = true; //useSelector(selectorParametroAsBoolean('PF047'));
  const [preferenciaUsuario, setPreferenciaUsuario] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePreferenciaUsuario = async () => {
    await print.savePreference(usuarioLogado?.id, { tipoDocumento, ...preferenciaUsuario });
  };

  const handleAdministracaoTEF = async () => {
    try {
      dispatch(
        appActions.setShowModalMenuTef({
          show: true,
          inicializado: false,
          title: 'Administração TEF',
          administracao: true
        })
      );
      await signalr.invokeInicializaTef();

      await signalr.invokeAdministracaoTef();
    } catch (error) {
      dispatch(appActions.setShowModalMenuTef({ show: false }));
    }
  };

  const handleCancelaPagamentoTEF = async () => {
    try {
      dispatch(
        appActions.setShowModalMenuTef({
          show: true,
          inicializado: false,
          title: 'Cancelamento TEF',
          administracao: true
        })
      );
      await signalr.invokeInicializaTef();

      await signalr.invokeCancelaPagamentoTef(157);
    } catch (error) {
      dispatch(appActions.setShowModalMenuTef({ show: false }));
    }
  };

  useEffect(() => {
    const usuarioId = usuarioLogado?.id;
    const _preferencia = print.getPreference(usuarioId, tipoDocumento);
    if (_preferencia) {
      setPreferenciaUsuario(_preferencia);
    }
  }, [tipoDocumento]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        padding={'4px 16px'}
        marginLeft={1}
        borderRadius={4}
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <IconAccountCircle className={classes.avatar} />
        <IconKeyboardArrowDown />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/*<MenuItem component={RouterLink} to="/app/perfil">
          Perfil
        </MenuItem> */}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="spacing-between"
          alignItems="start"
          p={2}
        >
          <IconAccountCircle className={classes.avatar} />
          <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
            <Typography variant="h6" noWrap color="inherit">
              {usuarioLogado?.nome}
            </Typography>
            <Typography variant="caption" color="inherit">
              {usuarioPerfil?.descricao}
            </Typography>
            <Typography variant="caption" color="inherit">
              Versão {APP_VERSION}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {showPreferences && (
          <>
            <Divider />
            <MenuItem>
              <Switch
                checked={preferenciaUsuario?.imprimirAposSalvar}
                onChange={e =>
                  setPreferenciaUsuario(state => ({
                    ...state,
                    imprimirAposSalvar: e.target.checked
                  }))
                }
              />
              Imprimir após salvar?
            </MenuItem>
            {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && (
              <MenuItem>
                <Switch
                  checked={preferenciaUsuario?.imprimirExpedicao}
                  onChange={e =>
                    setPreferenciaUsuario(state => ({
                      ...state,
                      imprimirExpedicao: e.target.checked
                    }))
                  }
                />
                Imprimir Expedição?
              </MenuItem>
            )}

            <MenuItem>
              <Switch
                checked={preferenciaUsuario?.utilizaServidorImpressao}
                onChange={e =>
                  setPreferenciaUsuario(state => ({
                    ...state,
                    utilizaServidorImpressao: e.target.checked
                  }))
                }
              />
              Utilizar servidor de impressão?
            </MenuItem>
            {preferenciaUsuario?.utilizaServidorImpressao && (
              <Box bgcolor="rgba(0, 0, 0, 0.1)" p={2}>
                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-size-small"
                      label="Servidor"
                      value={preferenciaUsuario?.servidor}
                      onChange={e =>
                        setPreferenciaUsuario(state => ({
                          ...state,
                          servidor: e.target.value
                        }))
                      }
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-size-small"
                      label="Porta"
                      value={preferenciaUsuario?.porta}
                      onChange={e =>
                        setPreferenciaUsuario(state => ({
                          ...state,
                          porta: e.target.value
                        }))
                      }
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 8 }}
                  onClick={() => handlePreferenciaUsuario()}
                >
                  Salvar e testar
                </Button>
              </Box>
            )}
          </>
        )}
        {APPLICATION !== 'web' && (
          <>
            {/* <MenuItem>
              <Switch
                checked={isOffline ?? false}
                onChange={() => dispatch(appActions.forceOfflineMode(!isOffline))}
              />
              Modo offline
            </MenuItem> */}

            <Divider />
            {showHelp && (
              <>
                <Divider />
                <MenuItem
                  onClick={() => dispatch(pdvActions.setShowModalAtalhosPdv({ show: true }))}
                >
                  <IconHelp /> <Box ml={2}>Ajuda</Box>
                </MenuItem>
              </>
            )}
          </>
        )}

        {APPLICATION !== 'web' && (
          <>
            {location.pathname !== '/pdv' && (
              <>
                {/* <MenuItem disabled={!isOnline} onClick={() => dispatch(appActions.downloadData())}>
                  <MuiIcons.CloudDownload /> <Box ml={2}>Sincronizar dados</Box>
                </MenuItem> */}

                <MenuItem
                  onClick={() => {
                    dispatch(appActions.setShowModalConfiguracao({ show: true }));
                  }}
                >
                  <MuiIcons.Settings /> <Box ml={2}>Configuração</Box>
                </MenuItem>
              </>
            )}

            {/* <MenuItem onClick={() => handleAdministracaoTEF()}>
              <MuiIcons.CreditCard /> <Box ml={2}>Administração TEF</Box>
            </MenuItem> */}

            <Divider />
          </>
        )}

        <MenuItem onClick={() => dispatch(pdvActions.setShowModalTrocaUsuario({show: true, desativarModalsSubsequentes: true}))}>
          <SwapHoriz /> <Box ml={2}>Trocar Usuário </Box>
        </MenuItem>

        <MenuItem onClick={() => dispatch(authActions.logoutRequest())}>
          <IconExitToApp /> <Box ml={2}>Sair</Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
