import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { APP_ERROR } from '~/constants';
import toast from '~/services/toast';
import history from '~/services/history';
import normalizeError from '~/utils/normalize-error';
import * as authActions from './auth/auth-actions';

import app from "./app/app-sagas";
import auth from './auth/auth-sagas';
import pdv from './pdv/pdv-sagas';
import monitor from './monitor/monitor-sagas';

export function* erroHandler({ error }) {
  const message = normalizeError(error);

  // Captura o erro no Sentry com mais contexto
  Sentry.withScope((scope) => {
    scope.setTag("error_type", "saga_error");
    scope.setLevel("error");

    if (error?.response) {
      scope.setTag("http_status", error.response.status);
      scope.setExtra("response_data", error.response.data);
      scope.setExtra("headers", error.response.headers);
    }

    Sentry.captureException(error);
  });

  // Tratamento do erro
  if (error?.response?.status === 401 && !error?.response?.headers?.ignoreheaders) {
    yield put(authActions.logoutRequest());

    yield call(history.push, '/auth/login');
    yield call(toast.warning, message);
  } else {
    yield call(toast.error, message);
  }
}

export default function* rootSaga() {
  yield all([takeLatest(APP_ERROR, erroHandler), app, auth, pdv, monitor]);
}
