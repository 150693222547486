import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';

const isWeb = process.env.REACT_APP_APPLICATION === 'web';
const isProduction = process.env.NODE_ENV === 'production';

if (false) {
  import('./config/ReactotronConfig');
}

import { SettingsProvider } from './contexts/settings-context';
import * as serviceWorker from './serviceWorker';
import store from './store';
import * as Sentry from '@sentry/react';

// Configuração do Sentry
Sentry.init({
  dsn: 'https://0c22e9ae56a5920b6bbb02ca41561f7b@o320094.ingest.us.sentry.io/4508962478620672',
});

ReactDOM.render(
    <>
    <Provider store={store}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </Provider>
    </>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
