import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { SHOW_SHORTCUTS, TIPO_DOCUMENTO, STATUS_MONITOR_NFCE, FILTRO_DATA } from '~/constants';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import DocumentosaidaTable from '../../components/DocumentosaidaTable';
import ModalVisualizarDocumento from '~/pages/PainelVenda/modal/ModalVisualizarDocumento';
import ModalImportarDocumento from '~/pages/TelaVenda/modal/ModalImportarDocumento';
import ModalPreviewDanfe from '~/pages/TelaVenda/modal/ModalPreviewDanfe';
import { DocumentoSaidaOnlineService } from '~/store/modules/documento-saida/documento-saida.service';

const statusOptions = [
  {
    value: STATUS_MONITOR_NFCE.DOCUMENTOS,
    label: 'Documentos'
  },
  {
    value: STATUS_MONITOR_NFCE.NAO_TRANSMITIDO,
    label: 'Aguard. transmissao'
  },
  {
    value: STATUS_MONITOR_NFCE.TRANSMITIDOS,
    label: 'Transmitidos'
  },
  {
    value: STATUS_MONITOR_NFCE.CANCELADOS,
    label: 'Cancelados'
  }
];

const Documentosaida = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');

  const isOnline = useSelector(getApplicationIsOnline());
  const { terminal } = useSelector(state => state.app);
  const { empresa } = useSelector(state => state.auth);
  const {
    loading,
    filtroData,
    filtroDataOptions,
    status,
    totalizadores,
    page,
    rowsPerPage
  } = useSelector(state => state.monitor);
  const [showModalImportarDocumento, setShowModalImportarDocumento] = useState({
    show: false
  });
  const [showModalDanfe, setShowModalDanfe] = useState({
    show: false
  });
  const [showModalVisualizarDocumento, setShowModalVisualizarDocumento] = useState({
    show: false
  });
  const anchorRef = useRef();

    const handleSearch = async event => {
      try {
        dispatch(monitorActions.setLoading(true));
        let verificaNumero = false;
        const type = isNaN(parseFloat(searchTerm));
        if(!type) verificaNumero = true;
        let filter = verificaNumero 
        ? [`documentoNumero eq ${searchTerm} or contains(clienteNome,'${searchTerm}') or contains(fatVendedor/nome,'${searchTerm}')`] 
        : [`contains(clienteNome,'${searchTerm}') or contains(fatVendedor/nome,'${searchTerm}')`];
        const service = new DocumentoSaidaOnlineService();
        const { data } = await service.getAllDocumentos({
          offset: 0,
          limit: 0,
          filter
        });
        dispatch(monitorActions.loadDocumentosSearchSuccess({
          documentos: data.items,
          documentosAll: data.items,
          valorTotalDocumentos: data.items.reduce((total, item) => total + item.valorTotal, 0),
          quantidadeDocumentos: data.items.length
        }));
      } catch (err) {
        throw err;
      } finally {
        dispatch(monitorActions.setLoading(false));
      }
    };

  const handleTabsChange = (event, value) => {
    dispatch(monitorActions.setStatus(value));
  };

  useEffect(() => {
    if(!searchTerm) {
      dispatch(monitorActions.loadDocumentosRequest());
    }
  }, [status, page, filtroData, rowsPerPage, searchTerm]);

  useEffect(() => {
    dispatch(monitorActions.setStatus(STATUS_MONITOR_NFCE.DOCUMENTOS));
    return () => {
      dispatch(monitorActions.limparDados());
    };
  }, [isOnline]);

  useEffect(() => {
    if(searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        handleSearch()
      }, 1000)
  
      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchTerm])

  return (
    <>
      <Mui.Grid container>
        <Mui.Grid item md={12}>
          <Mui.Box p={2} display="flex" justifyContent="space-between">
            <Mui.Box>
              <Mui.Button
                variant="contained"
                color="primary"
                disabled={terminal === null}
                style={{ height: 44, color: 'white', backgroundColor: 'rgb(9, 168, 72)' }}
                onClick={() => {
                  history.push('/pdv', {
                    tipoDocumento: TIPO_DOCUMENTO.DOCUMENTOSAIDA
                  });
                }}
              >
                <MuiIcons.AddCircle />

                <Mui.Box component="span" ml={2}>
                  Documento de saída {SHOW_SHORTCUTS && '(CTRL+D)'}
                </Mui.Box>
              </Mui.Button>
              <Mui.Button
                variant="contained"
                disabled={terminal === null}
                style={{ height: 44, marginLeft: 12 }}
                onClick={() =>
                  setShowModalImportarDocumento({ show: true, type: TIPO_DOCUMENTO.PEDIDO })
                }
              >
                <MuiIcons.ImportExport /> <span style={{ marginLeft: 12 }}>Importar</span>
              </Mui.Button>
              <Mui.Button
                variant="contained"
                disabled={terminal === null}
                style={{ height: 44, marginLeft: 12 }}
                onClick={() =>
                  setShowModalImportarDocumento({ show: true, type: TIPO_DOCUMENTO.DOCUMENTOSAIDA })
                }
              >
                <MuiIcons.FileCopy /> <span style={{ marginLeft: 12 }}>Copiar</span>
              </Mui.Button>
            </Mui.Box>

            <Mui.Box display="flex" alignContent="center">
              <Mui.TextField
                disabled={loading}
                InputProps={{
                  style: {
                    height: 44
                  },
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <Mui.SvgIcon fontSize="small" color="action">
                        <MuiIcons.Search />
                      </Mui.SvgIcon>
                    </Mui.InputAdornment>
                  )
                }}
                placeholder="Pesquisar documento..."
                variant="outlined"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <Mui.ButtonGroup
                variant="contained"
                color="default"
                ref={anchorRef}
                aria-label="split button"
                style={{ marginLeft: 16 }}
              >
                {filtroDataOptions.map((f, index) => (
                  <Mui.Button
                    key={`pedido-filtro-data-${index}`}
                    color={f.filtro === filtroData ? 'primary' : 'default'}
                    onClick={() => dispatch(monitorActions.setFiltroData(f.filtro))}
                  >
                    {f.titulo}
                  </Mui.Button>
                ))}
              </Mui.ButtonGroup>
            </Mui.Box>
          </Mui.Box>

          <Mui.Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            value={status}
            variant="scrollable"
          >
            {statusOptions
              ?.map(s => ({ ...s, count: totalizadores ? totalizadores[s?.value] ?? 0 : 0 }))
              ?.map(tab => (
                <Mui.Tab
                  key={tab?.value}
                  value={tab?.value}
                  label={
                    <Mui.Box>
                      <Mui.Badge badgeContent={tab?.count} color="primary" />
                      <Mui.Typography>{tab?.label}</Mui.Typography>
                    </Mui.Box>
                  }
                />
              ))}
          </Mui.Tabs>

          <Mui.Divider />

          <DocumentosaidaTable />
        </Mui.Grid>
      </Mui.Grid>
      {showModalImportarDocumento.show && (
        <ModalImportarDocumento
          type={showModalImportarDocumento.type}
          show={showModalImportarDocumento.show}
          onClose={() => setShowModalImportarDocumento({ show: false })}
          callback={data => {
            history.push('/pdv', data);
          }}
        />
      )}
      {showModalDanfe.show && (
        <ModalPreviewDanfe
          data={{ documentoId: showModalDanfe?.data?.id }}
          showModal={showModalDanfe.show}
          onClose={() => setShowModalDanfe({ show: false })}
        />
      )}
      {showModalVisualizarDocumento.show && (
        <ModalVisualizarDocumento
          data={{ documentoId: showModalVisualizarDocumento?.data?.id }}
          showModal={showModalVisualizarDocumento.show}
          onClose={() => setShowModalVisualizarDocumento({ show: false })}
        />
      )}
    </>
  );
};

export default Documentosaida;
