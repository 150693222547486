import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import storeSynchronize from 'redux-localstore';
import logger from 'redux-logger';

import * as Sentry from '@sentry/react';

import reactotron from '~/config/ReactotronConfig';
import rootReducer from './modules/root-reducer';
import rootSaga from './modules/root-saga';

const isDevelopment = process.env.NODE_ENV === 'development';

// Middleware do Saga com captura de erros
const sagaMiddleware = createSagaMiddleware({
  sagaMonitor: isDevelopment ? reactotron?.createSagaMonitor() : undefined,
  onError: error => {
    Sentry.captureException(error);
    console.error('Erro no Saga:', error);
    
  }
});

// Middleware para capturar eventos do Redux
const sentryReduxMiddleware = (store) => (next) => (action) => {
  Sentry.addBreadcrumb({
    category: 'redux',
    message: `Action: ${action.type}`,
    level: 'info',
  });
  return next(action);
};

// Lista de middlewares
const middleWares = [logger, sagaMiddleware, thunk, sentryReduxMiddleware];

// Configuração do Redux
const enhancer = isDevelopment
  ? compose(reactotron?.createEnhancer(), applyMiddleware(...middleWares))
  : applyMiddleware(...middleWares);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Criando a store do Redux
const store = createStore(rootReducer, composeEnhancers(enhancer));

// Executando os Sagas
sagaMiddleware.run(rootSaga);

// Sincronização do estado local (somente para whitelist)
storeSynchronize(store, {
  whitelist: ['pdv'],
  storage: 'sessionStorage'
});

// Capturar exceções do Redux no Sentry
store.subscribe(() => {
  try {
    const state = store.getState();
    Sentry.setContext('Redux State', state);
  } catch (error) {
    Sentry.captureException(error);
  }
});

export default store;
