import api, { apiVendas } from '~/services/api';
import {
  API_BASE_URL,
  API_LOCAL_URL,
  TIPO_DOCUMENTO,
  APPLICATION_MODE,
  API_VENDAS_URL
} from '~/constants';

class PdvService {
  _strategy = null;

  setStrategy(tipoDocumento) {
    if (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) {
      this._strategy = new OrcamentoServiceStrategy();
    } else if (tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
      this._strategy = new PedidoServiceStrategy();
    } else if (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      this._strategy = new DocumentosaidaServiceStategy();
    }
  }

  async enviarDocumento(payload) {
    if (!this._strategy) {
      throw new Error('Informar tipo de documento');
    }

    return this._strategy.store(payload);
  }

  async getDocumento(payload) {
    if (!this._strategy) {
      throw new Error('Informar tipo de documento');
    }

    return this._strategy.get(payload);
  }
}

class OrcamentoServiceStrategy {
  async store({ isOnline, ...payload }, opts) {
    const id = isOnline ? payload?.id : payload?._id;
    return isOnline
      ? id
        ? api.put(`${API_BASE_URL}/v1/faturamento/orcamento/${id}`, payload)
        : api.post(`${API_BASE_URL}/v1/faturamento/orcamento/`, payload, opts)
      : id
      ? api.put(`${API_LOCAL_URL}/faturamento/orcamento/${id}`, payload)
      : api.post(`${API_LOCAL_URL}/faturamento/orcamento/`, payload);
  }

  async get({ isOnline, ...payload }) {

    return api.get(
      !isOnline && payload?._id
        ? `${API_LOCAL_URL}/faturamento/orcamento/${payload?._id}`
        : `${API_BASE_URL}/v1/faturamento/orcamento/${payload?.id}`
    );
  }
}

class PedidoServiceStrategy {
  async store({ isOnline, ...payload }, opts) {

    const id = isOnline ? payload?.id : payload?._id;
    return isOnline
      ? id
        ? apiVendas.put(`/v1/faturamento/pedido/${id}`, payload)
        : apiVendas.post(`/v1/faturamento/pdv/pedido`, payload, opts)
      : id
      ? api.put(`${API_LOCAL_URL}/faturamento/pedido/${id}`, payload)
      : api.post(`${API_LOCAL_URL}/faturamento/pedido/`, payload);
  }

  async get({ isOnline, ...payload }) {

    return api.get(
      !isOnline && payload?._id
        ? `${API_LOCAL_URL}/faturamento/pedido/${payload?._id}`
        : `${API_BASE_URL}/v1/faturamento/pedido/${payload?.id}`,
      {
        // query URL without using browser cache
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      }
    );
  }
}

class DocumentosaidaServiceStategy {
  async store({ isOnline, ...payload }, opts) {
    const id = isOnline ? payload?.id : payload?._id;
    return isOnline
      ? id
        ? apiVendas.put(`/v1/faturamento/documentosaida/${id}`, payload)
        : apiVendas.post(`/v1/faturamento/pdv/documentosaida`, payload, opts)
      : id
      ? api.put(`${API_LOCAL_URL}/faturamento/documentosaida/${id}`, payload)
      : api.post(`${API_LOCAL_URL}/faturamento/documentosaida/`, payload);
  }

  async get({ isOnline, ...payload }) {

    return api.get(
      !isOnline && payload?._id
        ? `${API_LOCAL_URL}/faturamento/documentosaida/${payload?._id}`
        : `${API_BASE_URL}/v1/faturamento/documentosaida/${payload?.id}`
    );
  }
}

export default new PdvService();
