import {
  all,
  call,
  put,
  select,
  delay,
  takeLatest,
  takeEvery,
  take,
  spawn
} from '@redux-saga/core/effects';
import { eventChannel } from 'redux-saga';

import { formatDatetime } from '~/utils/format';
import {
  APP_ERROR,
  APP_SYNC_DATE,
  APPLICATION,
  APP_TOKEN,
  APP_TERMINAL_PDV,
  APP_FORCE_OFFLINE,
  API_TERMINAL_TEF_URL,
  APPLICATION_MODE,
  DATATYPE_TEF,
  API_LOCAL_URL
} from '~/constants';
import {
  DOWNLOAD_DATA,
  INITIALIZE,
  FORCE_OFFLINE_MODE_REQUEST,
  INICIALIZAR_TEF,
  MONTAR_RETORNOS_TEF,
  IMPRIMIR_COMPROVANTE_TEF,
  FINALIZAR_TEF,
  EXIBIR_QRCODE_TEF
} from './app-constants';
import {
  sendDownloadRequest,
  sendDownloadInitialRequest,
  sendProcessQueue
} from '~/services/events';
import * as authActions from '~/store/modules/auth/auth-actions';
import * as appActions from '~/store/modules/app/app-actions';

import toast from '~/services/toast';
import api from '~/services/api';
import { isWeb } from './app-selectors';

export function* initialise() {
  try {
    const isOnline = window.navigator.onLine;
    yield put(appActions.setConnectionMode(isOnline));
    yield put(
      appActions.setApplicationMode(isOnline ? APPLICATION_MODE.ONLINE : APPLICATION_MODE.OFFLINE)
    );

    const isWeb = APPLICATION === 'web';
    if (!isWeb) {
      //Terminal
      const terminal = localStorage.getItem(APP_TERMINAL_PDV) ?? null;
      yield put(appActions.setTerminal(terminal ?? null));
      if (!terminal) {
        yield put(appActions.setShowmodalIdentificacaoPDV({ show: true }));
      }

      //Offline
      const forceOffline = sessionStorage.getItem(APP_FORCE_OFFLINE);
      if (forceOffline === 'true') {
        yield put(appActions.forceOfflineMode(forceOffline === 'true'));
      }
      //Fila de documentos
      const { data } = yield call(api.get, `${API_LOCAL_URL}/queue`);

      yield put(
        appActions.setQueues(
          data?.map(x => ({ ...x.data, jobKey: x.key, tipoDocumento: x.tipoDocumento }))
        )
      );
    } else {
      yield put(appActions.setConnectionMode(window.navigator.onLine));
      yield put(appActions.setApplicationMode(APPLICATION_MODE.ONLINE));

      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      yield put(authActions.initialise(token));
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* downloadData({ schemas, inicialData }) {
  try {
    yield put(appActions.setShowModalSync(true));
    yield put(appActions.setShowProgressSync(true));

    const token = sessionStorage.getItem(APP_TOKEN);
    const terminal = localStorage.getItem(APP_TERMINAL_PDV);

    // console.log({ inicialData });

    if (inicialData) {
      sendDownloadInitialRequest(token, terminal);
    } else {
      sendDownloadRequest(token, terminal);
    }

    sessionStorage.setItem(APP_SYNC_DATE, JSON.stringify(formatDatetime(new Date())));
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    // yield put(appActions.setShowProgressSync(false));
    // yield put(appActions.setShowModalSync(false));
  }
}

export function* forceOfflineMode({ isOffline }) {
  if (isOffline) {
    yield put(appActions.setConnectionMode(false));
    yield put(appActions.setApplicationMode(APPLICATION_MODE.OFFLINE));
  } else {
    const isOnline = window.navigator.onLine;
    yield put(appActions.setConnectionMode(isOnline));
    yield put(
      appActions.setApplicationMode(isOnline ? APPLICATION_MODE.ONLINE : APPLICATION_MODE.OFFLINE)
    );

    // if (isOnline) {
    //   let userToken = sessionStorage.getItem(APP_TOKEN);
    //   let terminalPdv = localStorage.getItem(APP_TERMINAL_PDV);
    //   yield call(sendProcessQueue, userToken, terminalPdv);
    // }
  }

  yield put(appActions.forceOfflineModeSuccess(isOffline));
  sessionStorage.setItem(APP_FORCE_OFFLINE, isOffline);
}

export function* inicializarTef() {
  yield put(
    appActions.setShowModalMenuTef({
      inicializado: true,
      tef: null
    })
  );
}

export function* exibirQRCodeTef({ payload }) {
  yield put(
    appActions.setShowModalMenuTef({
      montaMenu: false,
      montaCampo: false,
      qrCode: payload.mensagem,
      tef: { ...payload }
    })
  );
  yield put(appActions.setMensagemTef(null));
}

export function* montarRetornosTef({ payload }) {
  if (payload?.dataType === DATATYPE_TEF.MENU) {
    yield put(
      appActions.setShowModalMenuTef({
        montaMenu: true,
        montaCampo: false,
        tef: { ...payload }
      })
    );
    yield put(appActions.setMensagemTef(null));
  } else if (
    payload?.dataType === DATATYPE_TEF.ALPHANUMERIC ||
    payload?.dataType === DATATYPE_TEF.CURRENCY ||
    payload?.dataType === DATATYPE_TEF.NUMERIC
  ) {
    yield put(
      appActions.setShowModalMenuTef({
        montaMenu: false,
        montaCampo: true,
        tef: { ...payload }
      })
    );
    yield put(appActions.setMensagemTef(null));
  } else if (payload?.dataType === DATATYPE_TEF.CONFIRMATION) {
    const { isConfirmed } = yield call(toast.confirm, 'ATENÇÃO', payload?.mensagem);
    if (isConfirmed) {
      yield call(api.post, `${API_TERMINAL_TEF_URL}/api/tef/callback`, {
        interromper: false,
        respostaSitef: '0'
      });
    } else {
      yield call(api.post, `${API_TERMINAL_TEF_URL}/api/tef/callback`, {
        interromper: true,
        respostaSitef: '1'
      });
    }
  } else if (
    payload?.dataType === DATATYPE_TEF.INFORMATION ||
    payload?.dataType === DATATYPE_TEF.AWAIT
  ) {
    yield call(toast.warning, payload?.mensagem);

    yield put(
      appActions.setShowModalMenuTef({
        montaMenu: false,
        montaCampo: false,
        tef: { ...payload }
      })
    );

    // yield call(toast.warning, payload?.mensagem);
  }
}
export function* imprimirComprovanteTef({ documentoVinculado }) {
  try {
    const transacaoTef = yield select(state => state.app.transacaoTef);
    const empresa = yield select(state => state.auth.empresa);

    yield put(
      appActions.setShowModalMenuTef({
        imprimindo: true
      })
    );
    yield delay(500);

    const payload = {
      ...transacaoTef,
      documentoVinculado,
      empresa
    };

    yield call(api.post, `${API_TERMINAL_TEF_URL}/api/tef/imprimir`, payload);
  } catch (error) {
    // console.log(`imprimirComprovanteTef`, error);
  }
}

export function* finalizarTef({ payload }) {
  const showModalMenuTef = yield select(state => state.app.showModalMenuTef);
  if (
    showModalMenuTef?.administracao &&
    (payload?.comprovanteCliente?.length > 0 || payload?.comprovanteEstab.length > 0)
  ) {
    yield put(appActions.setTransacaoTef(payload));
    yield put(appActions.imprimirComprovanteTef(''));

    yield put(appActions.resetModalMenuTef());
  } else {
    yield put(appActions.setTransacaoTef(payload));
  }
}

function* watchNetworkConnectivity() {
  if (!isWeb) return;

  const MINUTE_MILLISECONDS = 60000 * 5; // 5 minutos
  const terminalPdv = localStorage.getItem(APP_TERMINAL_PDV);
  const userToken = sessionStorage.getItem(APP_TOKEN);

  const channel = yield eventChannel(emitter => {
    const handleOnline = () => emitter(true);
    const handleOffline = () => emitter(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  });

  while (true) {
    const isOnline = window.navigator.onLine;
    const token = sessionStorage.getItem(APP_TOKEN);

    if (isOnline && token) {
      yield call(sendProcessQueue, userToken, terminalPdv);
      yield delay(MINUTE_MILLISECONDS);
    } else {
      yield take(channel);
    }
  }
}

export default function* rootSaga() {
  const sagas = [
    takeLatest(INITIALIZE, initialise),
    takeLatest(DOWNLOAD_DATA, downloadData),
    takeLatest(FORCE_OFFLINE_MODE_REQUEST, forceOfflineMode),
    takeLatest(INICIALIZAR_TEF, inicializarTef),
    takeLatest(MONTAR_RETORNOS_TEF, montarRetornosTef),
    takeLatest(EXIBIR_QRCODE_TEF, exibirQRCodeTef),
    takeLatest(IMPRIMIR_COMPROVANTE_TEF, imprimirComprovanteTef),
    takeLatest(FINALIZAR_TEF, finalizarTef)
  ];

  if (!isWeb) {
    sagas.push(spawn(watchNetworkConnectivity));
  }

  yield all(sagas);
}