import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components/macro';
import { create } from 'jss';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import localePtBR from 'date-fns/locale/pt-BR';
import Lottie from 'react-lottie';

import GlobalStyles from '~/styles/global';
import { createMuiTheme } from '~/styles/theme';

import { APPLICATION, APPLICATION_MODE, APP_TERMINAL_PDV, APP_TOKEN, THEMES } from './constants';
import * as appActions from '~/store/modules/app/app-actions';
import * as authActions from '~/store/modules/auth/auth-actions';
import {
  updateLocalData,
  subscribleDownloadInitial,
  subscribleDownloadUpdateMessage,
  subscribleDownloadListaRetornos,
  subscribleDownloadFinish,
  subscribleDownloadFailure,
  sendConnectionStatus,
  sendProcessQueue,
  sendPendingInQueue,
  subscribleQueueInitial,
  subscribleQueueMessage,
  subscribleQueueFailure,
  subscribleQueueFinish,
  subscriblePendingInQueue
} from '~/services/events';
import toast from '~/services/toast';
import socket from '~/services/socket';
import signalr from '~/services/signalr';
import Notifier from '~/services/notifier';
import useSettings from '~/hooks/useSettings';

import ModalSync from '~/components/ModalSync';
import SnackbarSync from './components/SnackbarSync';
import SnackbarOffline from './components/SnackbarOffline';
import SnackbarDownload from './components/SnackbarDownload';
import animationNoConnection from '~/assets/animations/no-connection.json';
import Routes from '~/Routes';
import ModalTrocaUsuario from './pages/TelaVenda/modal/ModalTrocaUsuario';
import './utils/decimalAdjust'

const isWeb = APPLICATION === 'web';
const jss = create({ plugins: [...jssPreset().plugins] });

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: '#fff'
  }
}));
const MINUTE_MILLISECONDS = 60000 * 5;

const checkIfPageClosed = () => {
  // if (window.performance) {
    // console.info("window.performance works fine on this browser");
  // }
  console.info(performance.navigation.type);
  if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    // console.info( "This page is reloaded" );
    return false;
  } else {
    // console.info( "This page is not reloaded");
    const value = sessionStorage.getItem('SIAFPLUS_LOGIN');
    if(value) 
      return false;
    else
      return true;
  }
}

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { settings } = useSettings();
  const { showModalTrocaUsuario } = useSelector(state => state.pdv);
  const user = useSelector(state => state?.auth?.usuario ?? 0);

  const { isInternetReachable, isOffline } = useSelector(state => state.app);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarDownload, setShowSnackbarDownload] = useState({
    show: false,
    retornos: []
  });
  const [showSnackbarSendDocument, setShowSnackbarSendDocument] = useState({
    show: false,
    message: ''
  });

  const configureSignalr = async () => {
    signalr.onInicializaTef(() => {
      dispatch(appActions.inicializarTef());
    });

    signalr.onExibeMensagemTef(mensagem => {
      dispatch(appActions.setMensagemTef(mensagem));
    });

    signalr.onMontaMenuTef(async payload => {
      dispatch(appActions.montarRetornosTef(payload));
    });

    signalr.onExibeQRCodeTef(payload => {
      dispatch(appActions.exibirQRCodeTef(payload));
    });

    signalr.onFinalizacaoTef(payload => {
      dispatch(appActions.finalizarTef(payload));
    });

    try {
      await signalr.connect();
    } catch (error) {
      console.log('Falhou a conexão com signalr =>', error?.toString());
    }
  };

  useEffect(() => {
    const check = checkIfPageClosed();
    if(check) dispatch(authActions.logoutRequest());
    let terminalPdv = sessionStorage.getItem(APP_TERMINAL_PDV);
    let userToken = sessionStorage.getItem(APP_TOKEN);    

    dispatch(appActions.initialise());
    dispatch(authActions.initialise(userToken));

    if (isWeb) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      if(token) {
        dispatch(authActions.initialise(token));
      }

      dispatch(appActions.setConnectionMode(true));
      dispatch(appActions.setApplicationMode(APPLICATION_MODE.ONLINE));
    } else {
      socket.disconnect();
      socket.connect(userToken, terminalPdv);
      socket.subscribeUpdateQueueJobs(data => {
        dispatch(
          appActions.setQueues(
            data?.map(x => ({ ...x.data, jobKey: x.key, tipoDocumento: x.tipoDocumento }))
          )
        );
      });
      socket.subscribeStartQueue(() => {
        dispatch(appActions.setShowProgressQueue(true));
      });
      socket.subscribeUpdateQueueMessage(async data => {
        if (data?.type === 'statusbar') {
          dispatch(appActions.setMensagemQueue(data?.message?.toString()));
        } else if (data?.type === 'toast') {
          // await toast.success(data?.message?.toString());
        }
      });
      socket.subscribeEndQueue(() => {
        dispatch(appActions.setShowProgressQueue(false));
      });

      if (terminalPdv) {
        sendProcessQueue(userToken, terminalPdv);
      }

      // if (userToken) {
      //   api.post(`${API_LOCAL_URL}/auth/refresh-token`).then(({ data }) => {
      //     userToken = data?.token;

      //     if (userToken) {
      //       sessionStorage.setItem(APP_TOKEN, userToken);

      //       sendProcessQueue(userToken);
      //     }
      //   });
      // }

      subscribleDownloadUpdateMessage((_, mensagemSync) => {
        dispatch(appActions.setMensagemSync(mensagemSync?.toString()));
      });
      subscribleDownloadInitial(() => {
        dispatch(appActions.setShowProgressSync(true));
      });
      subscribleDownloadFinish((_, retornos) => {
        dispatch(appActions.setShowProgressSync(false));

        setShowSnackbarDownload({
          show: true,
          retornos
        });
      });
      subscribleDownloadFailure(() => {
        dispatch(appActions.setShowProgressSync(false));
      });

      window.addEventListener('offline', () => {
        dispatch(appActions.setConnectionMode(false));
        dispatch(
          appActions.setApplicationMode(isWeb ? APPLICATION_MODE.ONLINE : APPLICATION_MODE.OFFLINE)
        );
        setShowSnackbar(true);
  
        if (!isWeb) {
          sendConnectionStatus('offline');
        }
      });
  
      window.addEventListener('online', () => {
        if (!isOffline) {
          dispatch(appActions.setConnectionMode(true));
          dispatch(appActions.setApplicationMode(APPLICATION_MODE.ONLINE));
        }
  
        setShowSnackbar(true);
  
        if (!isWeb && isOffline) {
          sendConnectionStatus('online');
        }
  
        // let userToken = sessionStorage.getItem(APP_TOKEN);
        // let terminalPdv = sessionStorage.getItem(APP_TERMINAL_PDV);
        // setTimeout(() => {
        //   sendProcessQueue(userToken, terminalPdv);
        // }, 60000);
      });
    }

    configureSignalr();    

    return () => {
      if (!isWeb) {
        setShowSnackbar(false);
      }
    };
  }, []);

  return (
    <>
      <Helmet titleTemplate="%s | GolERP - PDV" defaultTitle={`GolERP PDV - ${user?.nome}`} />
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={createMuiTheme({ theme: settings?.theme ?? THEMES.DEFAULT })}>
          <ThemeProvider theme={createMuiTheme({ theme: settings?.theme ?? THEMES.DEFAULT })}>
            <LocalizationProvider dateAdapter={DateFnsAdapter} locale={localePtBR}>
              <SnackbarProvider dense maxSnack={5}>
                <CssBaseline />
                <GlobalStyles />
                {/* <ScrollReset /> */}
                <Notifier />
                <Routes />
                {showSnackbar && isWeb && (
                  <SnackbarOffline
                    open={showSnackbar}
                    callback={() => {
                      setShowSnackbar(false);
                    }}
                  />
                )}
                {showSnackbarDownload && (
                  <SnackbarDownload
                    open={showSnackbarDownload?.show}
                    retornos={showSnackbarDownload?.retornos}
                    callback={() => {
                      setShowSnackbarDownload({ show: false });
                    }}
                  />
                )}
                {showSnackbarSendDocument?.show && (
                  <SnackbarSync
                    open={showSnackbarSendDocument?.show}
                    message={showSnackbarSendDocument?.message}
                  />
                )}

                  {(showModalTrocaUsuario?.show || showModalTrocaUsuario?.confirmLogin) && <ModalTrocaUsuario />}
                
                {/* {showProgressSync && mensagemSync && (
                  <SnackbarSync open={showProgressSync} message={mensagemSync} />
                )} */}
                {/* {showModalSync && <ModalSync />} */}
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>

      {isWeb && !isInternetReachable && (
        <Backdrop className={classes.backdrop} open={isWeb && isInternetReachable}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationNoConnection,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={256}
            width={256}
            isStopped={false}
            isPaused={false}
          />
        </Backdrop>
      )}
    </>
  );
};

export default App;
